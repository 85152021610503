import { useState } from "react";
import { useHistory } from "react-router";
import "./LoginPage.css"

const LoginPage = ({setAuthenticated}) => {

    const defaultUsername = autoDetectUsername();

    const [username, setUsername] = useState(defaultUsername);
    const [password, setPassword] = useState("");
    const [passwordPlaceholder, setPasswordPlaceholder] = useState("password");
    const [passwordClassname, setPasswordClassname] = useState("login-input outline-subtle");

    let history = useHistory()
    /**
     * Function that checks the localStorage and finds the 
     * @returns {string} the right default username
     */
    function autoDetectUsername() {
        const storedUsername = localStorage.getItem("username");

        if (storedUsername === null) { 
            return ""; 
        } else {
            return storedUsername;
        }
    }

    /**
     * Function that handles the submission of login credentials
     * @param {event} e 
     */
    async function handleSubmit(e) {
        e.preventDefault();

        if (!username || !password) {
            setPasswordPlaceholder("Login failed. Please try again.");
            setPasswordClassname("login-input outline-subtle-red");
            return false;
        }

        // save the current username in localStorage
        localStorage.setItem("username", username);


        const serverResponse = await fetch("/login", {
            method: "POST", 
            body: `username=${username}&password=${password}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })

        switch (serverResponse.status) {
            case 200:
                setPassword("");
                setAuthenticated(true);
                history.push("/update")
                break;
            case 204:
                setAuthenticated(false);
                setPassword("");
                setPasswordPlaceholder("Login failed. Please try again.");
                setPasswordClassname("login-input outline-subtle-red");
                break;
            case 500:
                setAuthenticated(false);
                setPassword("");
                setPasswordClassname("login-input outline-subtle-red");
                setPasswordPlaceholder("Apologies! LDAP server is down. Please try again later.");
                
                break;
        }
    }

    function onChangeUsername(e) {
        const inputUsername = e.target.value;
        setUsername(inputUsername)
    }

    function onChangePassword(e) {
        setPassword(e.target.value);
        setPasswordClassname("login-input outline-subtle");
    }

    return ( 
    <div>
        <div id="login-top-section-container">

                <h2>Please use your UOPX credentials to login.</h2>
                
                <div id="login-form-container">
                    <form id="login-form" method="POST" onSubmit={handleSubmit}>
                        <input type="text" id="username" className="login-input outline-subtle" name="username" value={username} placeholder="username" onChange={onChangeUsername} />
                        <br />
                        <input type="password" id="password" className={passwordClassname} name="password" value={password} autoComplete="off" onChange={onChangePassword} onClick={onChangePassword} placeholder={passwordPlaceholder} />
                        <br />
                        <input type="submit" id="login-button" className="login-button" value="Login" />
                    </form>
                </div>

        </div>
    </div>
    );
}
 
export default LoginPage;