import { useState } from "react"
import { Link } from "react-router-dom";
import axios from "axios";
import iconTrash from "../../../image/icon-trash.png"
import FileUploadForm from "../shared-components/file-upload-form/FileUploadForm";
import HomeNavBar from "../shared-components/home-nav-bar/HomeNavBar";

const HomeCreatePage = ({setUserId, setTotalNumOfDocuments, environmentToUse, setEnvironmentToUse, filesSelected, setFilesSelected, step, setStep, setOperation}) => {
    
    const onFileChange = (event) => {
        const files = event.target.files;
        setFilesSelected(files);
        setTotalNumOfDocuments(files.length);
        setStep(2);
    }

    const onRemoveFileFromSelection = (index) => {
        const filesSelectedCopy = [...filesSelected]
        filesSelectedCopy.splice(index, 1);
        setFilesSelected(filesSelectedCopy);
    }

    const submitTemplate = async () => {
        let requestEndpoint = "/upload-for-template-creation";

        const formData = new FormData();
        for (const file of filesSelected) {
            formData.append("file", file, file.name);
        }
        const response = await axios({
            method: "post",
            url: requestEndpoint,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
                "X-Environment": environmentToUse
            }
        })
        
        setUserId(response.data.id);
    }

    
    return ( 
    <div className="homepage-container">
        <HomeNavBar setOperation={setOperation}/>

        <div className="homepage-main-content-container">
            
            <FileUploadForm 
                headerText={"Create new DocuSign templates with pdf/HTML files"}
                onFileChange={onFileChange} 
                environmentToUse={environmentToUse} 
                setEnvironmentToUse={setEnvironmentToUse}
            />

            { step == 2 && 
                <div id="list-of-files-container">
                    <h2>{filesSelected.length} files selected: </h2>
                    <ol id="list-of-files">
                        {Array.from(filesSelected).map((file, i) => {
                            return (
                                <li key={i}>
                                    {file.name}
                                    <img src={iconTrash} className="trash-can-icon" onClick={() => {onRemoveFileFromSelection(i)}}/>
                                </li>
                            )
                        })}
                    </ol>
                <Link to="/waiting-page" className="submit-button" id="file-upload-button" onClick={submitTemplate}>Create templates</Link>
                </div>
            }
        </div>

    </div> 
    );
}
 
export default HomeCreatePage;