import { useState , useEffect, useRef } from "react";
import "./WaitingPage.css"
import ProgressBar from 'react-bootstrap/ProgressBar'
import downloadIcon from "../../image/download-icon-3.png"
import ResultReportSection from "./result-report/ResultReportSection";

const WaitingPage = ({userId, totalNumOfDocuments, operation, environmentToUse}) => {

    const checkJobTimer = useRef();

    const [finishedDocumentsCount, setFinishedDocumentsCount] = useState(0);
    const [errorDocuments, setErrorDocuments] = useState([]);
    const [successfulDocuments, setSuccessfulDocuments] = useState([]);
    const [zipFolderName, setZipFolderName] = useState("");
    
    const requestStatus = async () => {
        const response = await fetch(`/check-job-status?id=${userId}`);
        const responseJSON = await response.json();
        if (!responseJSON.loaded) {
            return;
        }

        setFinishedDocumentsCount(responseJSON.finishedDocuments);
        
        if (responseJSON.zipFolderName) {
            clearInterval(checkJobTimer.current);
        }

        setErrorDocuments(responseJSON.errorDocuments);
        setSuccessfulDocuments(responseJSON.successfulDocuments);
        setZipFolderName(responseJSON.zipFolderName);

    }

    useEffect(() => {
        if (checkJobTimer.current != null) {
            clearInterval(checkJobTimer.current);
        }
        const timer = setInterval(requestStatus, 1000);
        checkJobTimer.current = timer;
    }, [userId])


    const allDocumentsProcessed = finishedDocumentsCount >= totalNumOfDocuments;
    let progressBarValue;
    if (totalNumOfDocuments == 0 || finishedDocumentsCount / totalNumOfDocuments * 100 < 1) {
        progressBarValue = 1;
    } else {
        progressBarValue = Math.floor(finishedDocumentsCount / totalNumOfDocuments * 100);
    }

    return ( 
    <div className="waiting-page-container">
        
        <div id="processing-section-container">
            <h2 id="processing-text">
                {!allDocumentsProcessed && <span>Processing...</span>}
                { allDocumentsProcessed && <span>All documents have been processed.</span>}
            </h2>
            
            <div id="job-status-message">
                Processed {finishedDocumentsCount} of {totalNumOfDocuments} files.

                { zipFolderName && 
                <a href={`/output_zips/${zipFolderName}`} className="zip-download-link">
                    <img src={downloadIcon} className="download-icon"/>
                    Cleaned PDFs 
                </a>}
            </div>
            
            {!allDocumentsProcessed && <ProgressBar animated now={progressBarValue} max={100}/>}
            { allDocumentsProcessed && <ProgressBar now={100} max={100}/>}
        </div>
        <div>
        </div>
        
        <ResultReportSection {...{successfulDocuments, errorDocuments, environmentToUse, operation}}/>

        </div> 
    );
}
 
export default WaitingPage;