import iconOpened from "../../../../image/icon-opened-yellow.svg"
import EnvironmentToggler from "../environment-toggler/EnvironmentToggler";
import "./FileUploadForm.css"

const FileUploadForm = ({headerText, onFileChange, environmentToUse, setEnvironmentToUse, hideToggle}) => {
    return ( 
        <form action="/" method="POST" encType = "multipart/form-data" className="homepage-form" id="template-creation-form">
            <div className="form-container">
                <h2>{headerText}</h2>
                <input type="file" name="file" multiple id="template-creation-browse" onChange={onFileChange}/>
                <div className="label-container">
                    {!hideToggle && <EnvironmentToggler environmentToUse={environmentToUse} setEnvironmentToUse={setEnvironmentToUse}/>}
                    <label htmlFor="template-creation-browse" className="file-selector">
                        Browse files
                        <br />
                        <img src={iconOpened} className="folder-open-svg"/>
                    </label>
                </div>
            </div>
    </form> 
    );
}
 
export default FileUploadForm;