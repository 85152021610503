
import FailedDocsReport from "./failed-docs-report/FailedDocsReport";
import SuccessfulDocsReport from "./successful-docs-report/SuccessfulDocsReport";
import "./ResultReportSection.css"
const ResultReportSection = ({successfulDocuments, errorDocuments, environmentToUse, operation}) => {
    return ( 
        <div id="job-report-container">

            <SuccessfulDocsReport {...{successfulDocuments, environmentToUse, operation}}/>

            <FailedDocsReport errorDocuments={errorDocuments}/>

        </div>

     );
}
 
export default ResultReportSection;