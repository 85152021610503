import { Link, useLocation } from "react-router-dom";
import "./HomeNavBar.css"

const HomeNavBar = ({setOperation}) => {
    const location = useLocation().pathname;
    return ( 
        <div className="nav-bar">
            <Link to="/update" className={location=="/update" ? "nav-bar-link on-this-link" : "nav-bar-link"} onClick={() => {setOperation("update")}}>Update</Link>
            <Link to="/create" className={location=="/create" ? "nav-bar-link on-this-link" : "nav-bar-link"} onClick={() => {setOperation("create")}}>Create</Link>
            <Link to="/convert" className={location=="/convert" ? "nav-bar-link on-this-link" : "nav-bar-link"} onClick={() => {setOperation("convert")}}>Convert</Link>
            <Link to="/help" className={location=="/help" ? "nav-bar-link on-this-link" : "nav-bar-link"}>Help</Link>
            <Link to="/edit-config"className={location=="/edit-config" ? "nav-bar-link on-this-link" : "nav-bar-link"}>Edit config</Link>
        </div>
     );
}
 
export default HomeNavBar;