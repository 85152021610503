import { useEffect, useState } from "react";
import checkJSONSyntax from "../../../function/checkJSONSyntax";
import checkmarkImage from "../../../image/icon-checkmark.png"
import HomeNavBar from "../shared-components/home-nav-bar/HomeNavBar";
import "./HomeConfigPage.css"

const HomeConfigPage = ({setOperation}) => {

    const [currentConfig, setCurrentConfig] = useState("");
    const [jsonIsValid, setJsonIsValid] = useState(true);
    const [submissionResult, setSubmissionResult] = useState("");

    useEffect(() => {
        
        const asyncWrapper = async () => {
            console.log("sending stuff");
            const serverResponse = await fetch("/get-config", {method: "GET"})
            const responseText = await (serverResponse.text());
            setCurrentConfig(responseText);

            const syntaxCheck = checkJSONSyntax(responseText);
            setJsonIsValid(syntaxCheck.toString());
        }
    
        asyncWrapper()
    }, [])

    const onTextChange = (e) => {
        e.preventDefault();
        setCurrentConfig(e.target.value);
        const syntaxCheck = checkJSONSyntax(e.target.value);
        setJsonIsValid(syntaxCheck.toString());
        setSubmissionResult("");
    }

    const submitConfig = async () => {
        const serverResponse = await fetch("/post-config", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
              },
            body: currentConfig
        })

        if (serverResponse.status == 200) {
            setSubmissionResult("success");
        } else {
            setSubmissionResult("failure");
        }

    }

    return ( 
        <div className="config-edit-page">
            <HomeNavBar setOperation={setOperation}/>
            <div className="config-edit-section">
                <div className="config-edit-section-left-row">
                    <form>
                    <textarea className="config-editor-textarea" defaultValue="loading config..." value={currentConfig} onChange={onTextChange} spellCheck="false" jsonisvalid={jsonIsValid}></textarea>
                    </form>
                </div>
                <div className="config-edit-section-right-row">
                    <h3>Miscellaneous instructions</h3>
                    <ul className="config-edit-instructions">
                        <li>Feel free to add new placeholders to this config as needed! But please reference the format of placeholders of similar types to ensure the fields are properly written.</li>
                        <li>Any placeholder whose name contains the word "adviser" in it (case-insensitive) will be processed as a DocuSign field meant for a 2nd signer, the UOPX approver, and not for the student.</li>
                        <li>tabType = "none" means that the placeholder will simply be removed, but not replaced with a DocuSign field.</li>
                        <li>Allowed font sizes in DocuSign are: 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72</li>
                        <li>"scaleValue" of initialHereTabs and signHereTabs must be between 0.5 and 1</li>
                        </ul>
                    <span className="submit-button" id="config-submit-button" onClick={submitConfig}>Save configuration</span>
                    
                    {submissionResult == "success" && 
                    <div className="config-submission-result">
                        Updated placeholders configuration successfully!
                        <img id="config-submission-checkmark-image" src={checkmarkImage} />
                    </div>
                    }
                </div>
            </div>
        </div>
     );
}
 
export default HomeConfigPage;