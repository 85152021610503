import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { useState } from 'react';
import "./EnvironmentToggler.css"
const EnvironmentToggler = ({environmentToUse, setEnvironmentToUse}) => {
    const [value, setValue] = useState([environmentToUse]);
  
    const handleChange = (val) => {
        setValue(val);
        setEnvironmentToUse(val);
    }
  
    return (
    <div className="toggle-environment">
      <ToggleButtonGroup  type="radio" name="options" value={value} onChange={handleChange}>
        <ToggleButton id="tbg-btn-1" value={"QA"}>QA</ToggleButton>
        <ToggleButton id="tbg-btn-2" value={"Prod"}>Prod</ToggleButton>
      </ToggleButtonGroup>
    </div>
    );
  }
  
  export default EnvironmentToggler