import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import WaitingPage from './waiting-page/WaitingPage';
import { useState } from 'react';
import HomeUpdatePage from './home-page/home-update-page/HomeUpdatePage';
import HomeCreatePage from './home-page/home-create-page/HomeCreatePage';
import HomeHelpPage from './home-page/home-help-page/HomeHelpPage';
import HomeConfigPage from './home-page/home-config-page/HomeConfigPage';
import HomeConvertPage from "./home-page/home-convert-page/HomeConvertPage"
import LoginPage from './login-page/LoginPage';

const App = () => {
  const [userId, setUserId] = useState("");
  const [totalNumOfDocuments, setTotalNumOfDocuments] = useState(0);
  const [operation, setOperation] = useState("update");
  const [environmentToUse, setEnvironmentToUse] = useState("QA");
  const [filesSelected, setFilesSelected] = useState([]);
  const [step, setStep] = useState(1);
  const [authenticated, setAuthenticated] = useState(false);

  const logOut = async () => {
    setAuthenticated(false);
    fetch("/logout", {method: "POST"});
  }

  

  return (
    <div className="App">
      <Router>
        <div className="top-bar">
          <h1>
            <Link to="/" className="title-link">
            Template Crunch
            <span id="non-bold-h1">~ Generate DocuSign templates in bulk</span>
            </Link>
            
          </h1>
          { authenticated && <span className="logout-button" onClick={logOut}>Logout</span>}
        </div>
      <Switch>

          <Route path="/" exact>
             {!authenticated && <Redirect to="/login" />}
             { authenticated && <Redirect to="/update" />}
          </Route>

          <Route path="/login" exact>
            <LoginPage setAuthenticated={setAuthenticated}/>
          </Route>

          <Route path="/update">
            {!authenticated && <Redirect to="/login" />}
            <HomeUpdatePage {...{setUserId, setTotalNumOfDocuments, environmentToUse, setEnvironmentToUse, filesSelected, setFilesSelected, step, setStep, setOperation}}/>
          </Route>
          
          <Route path="/create">
            {!authenticated && <Redirect to="/login" />}
            <HomeCreatePage {...{setUserId, setTotalNumOfDocuments, environmentToUse, setEnvironmentToUse, filesSelected, setFilesSelected, step, setStep, setOperation}}/>
          </Route>

          <Route path="/convert">
            {!authenticated && <Redirect to="/login" />}
            <HomeConvertPage {...{setUserId, setTotalNumOfDocuments, environmentToUse, setEnvironmentToUse, filesSelected, setFilesSelected, step, setStep, setOperation}}/>
          </Route>

          <Route path="/help">
            {!authenticated && <Redirect to="/login" />}
            <HomeHelpPage setOperation={setOperation}/>
          </Route>

          <Route path="/edit-config">
            {!authenticated && <Redirect to="/login" />}
            <HomeConfigPage setOperation={setOperation}/>
          </Route>

          <Route path="/waiting-page">
            {!authenticated && <Redirect to="/login" />}
            <WaitingPage {...{userId, totalNumOfDocuments, operation, environmentToUse}}/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
