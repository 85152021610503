import "./HomeHelpPage.css"
import HomeNavBar from "../shared-components/home-nav-bar/HomeNavBar";
const HomeHelpPage = ({setOperation}) => {
    return ( 
    <div className="help-section">
        <HomeNavBar setOperation={setOperation}/>
        <h2>Use this website to create/edit DocuSign E-sign templates for enrollment agreements.</h2>
        <div className="instruction-texts">
            <ol className="help-section-ordered-list">
                <li>Prepare your documents for upload. Make sure they're in pdf or html format; for each DocuSign field you want to create/edit in the template, there must be a corresponding placeholder text.</li>
                <br/>
                <li>Upload your pdf documents onto this website.
                    <ol type="a">
                        <li>If the files you're trying to upload already have existing DocuSign templates in the cloud matching their filenames, then to edit those templates' documents &amp; fields using your new documents, use the <span className="inline-blue">"Update"</span> option.</li>
                        <li>If the files you're trying to upload don't have existing DocuSign templates, then to create brand new templates, use the <span className="inline-blue">"Create"</span> option.</li>
                        <li>If you only want to convert a PDF/HTML to be a clean PDf stripped of labels, use the <span className="inline-blue">"Convert"</span> option.</li>
                    </ol>
                </li>
                <br/>
                <li>After you've uploaded the documents, the website will process it and give you a report of whether documents' operations failed or succeeded.</li>
            </ol>
        </div>
    </div> 
    );
}
 
export default HomeHelpPage;