import redXImage from "../../../../image/icon-red-x.png"
import openNewTabIcon from "../../../../image/open-new-tab-icon.png"
import "./FailedDocsReport.css"
const FailedDocsReport = ({errorDocuments}) => {
    return ( 
    <div id="errored-documents-list-container" className="documents-list-container">
        {errorDocuments.length > 0 && 
        <label htmlFor="error-documents-list" id="error-documents-list-label">
            <img id="red-x-image" src={redXImage} />
            Template operation failed for these files:
            
        </label>
        }
        <ul id="error-documents-list">
            {Array.from(errorDocuments).map((document, i) => {
                return (
                <li key={i}>
                    {document.name}: {document.message}
                    {document.message.includes("lock") && 
                        <a href={`https://appdemo.docusign.com/prepare-template/${document.templateId}/`} className="link-to-edit-lock" target="_blank" >
                            Edit Lock
                            <img src={openNewTabIcon} className="link-to-template-icon"/>
                        </a>
                    }    
                </li>
                )
            })}
        </ul>
    </div> 
    );
}
 
export default FailedDocsReport;