import checkmarkImage from "../../../../image/icon-checkmark.png"
import openNewTabIcon from "../../../../image/open-new-tab-icon.png"
import "./SuccessfulDocsReport.css"
const SuccessfulDocsReport = ({successfulDocuments, operation, environmentToUse}) => {
    return (
    <div id="successful-documents-list-container" className="documents-list-container">
        {successfulDocuments.length > 0 && 
        <label htmlFor="successful-documents-list" id="successful-documents-list-label">
        <img id="little-checkmark-image" src={checkmarkImage} />
            {operation=="create" && `${successfulDocuments.length} Templates created successfully: `}
            {operation=="update" && `${successfulDocuments.length} Templates updated successfully: `}
        </label>
        }
        <ul id="successful-documents-list">
            {Array.from(successfulDocuments).map((document, i) => {
                const docNameWithoutFileExtension = document.name.substring(0,document.name.lastIndexOf("."));
                let urlToTemplate;
                if (environmentToUse == "QA") 
                    urlToTemplate = `https://appdemo.docusign.com/templates/details/${document.templateId}`;
                else 
                    urlToTemplate = `https://app.docusign.com/templates/details/${document.templateId}`;
                
                if (document.templateId == "none") {
                    return <span></span>
                }
                return (
                <li key={i}>
                    <a href={urlToTemplate} target="_blank" className="link-to-template">
                        {docNameWithoutFileExtension}
                        <img src={openNewTabIcon} className="link-to-template-icon"/>
                    </a>
                </li>
                )
            })}
        </ul>
    </div>);
}
 
export default SuccessfulDocsReport;